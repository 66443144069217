(function () {
    const template = document.createElement('template');
    template.innerHTML = `
    <style>
        * {box-sizing: border-box}

        .accordion {margin-bottom: 1px; overflow: hidden}
        .accordion-label {
            display: flex;
            align-items: center;
            width: 100%;
            background-color: rgba(0,0,0,.8);
            color: white;
            padding: 1em 2em;
            padding-left: 2.5em;
            transition: all 0.2s ease;
            cursor: pointer;
        }

        .accordion-label:before {
            content: 'chevron_right';
            transition: 0.2s ease all;
            font-family: 'Material Icons';
            color: white;
            margin-left: -1em;
            margin-right: 1em;
        }

        .active .accordion-label:before {
            transform: rotate(90deg);
        }

        .accordion-content {
            display: block;
            max-height: 0px;
            padding: 0 2em;
            opacity: 0;
            pointer-events: none;
            background-color: rgba(0,0,0,.1);
            transition: .2s ease all;
            overflow:hidden
        }

        .active .accordion-content {
            max-height: 1000px;
            padding: 2em;
            opacity: 1;
            display: block
        }
    </style>
    <div class="accordion">
        <div class="accordion-label"><slot name="label">My default label</slot></div>
        <div class="accordion-content"><slot name="content">My default text</slot></div>
    <div>
    `

    class TopAceTenisAccordion extends HTMLElement {

        constructor() {
            super();
            this.attachShadow({ mode: 'open'});
            this.shadowRoot.appendChild(template.content.cloneNode(true));
        }

        connectedCallback() {
            this.shadowRoot.querySelector('.accordion-label').addEventListener('click', () => this.shadowRoot.querySelector('.accordion').classList.toggle('active'))
        }
    }

    customElements.define('tat-accordion', TopAceTenisAccordion);
})()
