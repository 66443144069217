document.addEventListener("turbolinks:load", () => {
    fadeOut('js-notifications-bar', 'js-close_alert');

    $('.tooltipster').tooltipster(
        {
            interactive: true,
            trigger: 'custom',
            triggerOpen: {
                mouseenter: true,
                click: true,
                tap: true
            },
            triggerClose: {
                //mouseleave: true,
                click: true,
                //scroll: true,
                tap: true
            }
        }
    )
})


function fadeOut(class_element, class_button) {
    var elements = document.getElementsByClassName(class_element);

    if (elements.length > 0) {
        var source = elements[0];

        document.getElementsByClassName(class_button)[0].onclick = function () {
            source.classList.toggle('fade');
        }

    }
}
