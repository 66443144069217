(function() {
    const template = document.createElement('template');

    template.innerHTML = `
    <style>
        :host {
            flex: 1;
            padding: 3em;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        ::slotted(a) {
            font-size: 1em;
            color: white;
            text-transform: uppercase;
            margin-left: 2em; 
        }

        ::slotted(a:hover) { color: #ffc400 }

        button {
            display:none;
            cursor: pointer;
            font-family: 'Material Icons';
            font-size: 2em;
            background: none; 
            border: none; 
            color:white
        }

        @media(max-width: 920px) {
            button {
                display:flex;
                align-items:center;
                justify-content: center;
                position: fixed;
                background-color: yellow;
                color: #333;
                right: 20px; 
                top: 60px;
                z-index:999;
                width: 52px;
                height: 52px;
                border-radius: 100%;
            }

            .nav {
                position: fixed;
                background-color: rgba(0,0,0,0.9);
                width: 350px;
                height: 100%;
                top: 0;
                left: 0;
                flex-direction: column;
                padding: 4em;
                justify-content: center;
                transition: .2s ease-in-out;
                opacity: 0;
                transform: translateX(-100%);
                z-index: 998
            }

            .nav.active {
                transform: translateX(0);
                opacity: 1;
            }

            ::slotted(a) {
                display:block;
                font-size: 1.5em;
                margin-bottom: 2em;
            }

            
        }


    </style>
    <div class="nav">
        <slot></slot>
    </div>
    <button>menu</button>
    `

    class TopAceTenisNavigation extends HTMLElement {
        constructor() {
            super();

            this.attachShadow({mode: 'open'});
            this.shadowRoot.appendChild(template.content.cloneNode(true));
        }
        
        connectedCallback() {
            this.shadowRoot.querySelector('button').addEventListener('click', () => this.shadowRoot.querySelector('.nav').classList.toggle('active'))
        }    
    }
    customElements.define('tat-navigation', TopAceTenisNavigation);
})()
